const globalConfig = require('../../config');
const { get: _get } = require('lodash');
const { isProxy } = require('./mode');
// const { getXPathForElement } = require('../../proxy/services/sentence_merging.service');
// const { getXPathForElement } = require('../services/sentence-merging-service')

function removeExtraSpacesInMiddle(str) {
  if (str) {
    const str1 = str.replace(/(\r\n|\n|\r|\t)/gm, ' ');
    const str2 = str1.replace(/[^\x20-\x7E™®©₹\xD7]/gim, '');

    return str2.replace(/ +/g, ' ');
  }
  return str;
}

function cleanString(str = '', trim = true) {
  let outputString = str;
  const removeNbspRegEx = new RegExp(String.fromCharCode(160), 'g');
  outputString = outputString.replace(removeNbspRegEx, ' ');
  outputString = outputString.replace(new RegExp('/<!--.*-->/s', 'g'), '');
  outputString = outputString.replace(new RegExp('~<!--(.*?)-->~s', 'g'), '');
  outputString = outputString.replace(new RegExp('~<!--(.*?)-->~s', 'g'), '');
  outputString = outputString.replace(new RegExp('/<!--(.|s)*?-->/', 'g'), '');
  outputString = outputString.replace(new RegExp('/<!--(.|s)*?-->/', 'g'), '');
  outputString = outputString.replace('\n', '');
  outputString = outputString.replace('\r', '');
  outputString = outputString.replace('\t', '');
  outputString = outputString.replace('\xc2\xa0', '');
  outputString = outputString.replace(new RegExp('/^[^a-zA-Z0-9]+$/', 'g'), '');
  outputString = outputString.replace(new RegExp('/^d+$/', 'g'), '');
  outputString = outputString.replace(new RegExp('/^[^a-zA-Z0-9]+d+$/', 'g'), '');
  outputString = removeExtraSpacesInMiddle(outputString);
  if (trim) {
    outputString = outputString.trim();
  }
  outputString = outputString.length > 0 ? outputString : '';

  if (isNaN(outputString)) {
    return outputString;
  }
  return '';

  // return outputString;
}

/**
 * Get configuration value from the global config
 * based on dot notation syntax.
 *
 * @param {string} path Path to the object property
 * @returns mixed
 */
function getConfig(path) {
  return _get(globalConfig, path);
}

/**
 * Get configuration of a specific feature.
 *
 * @param {string} name Name of the feature
 * @returns object
 */
function getFeatureConfig(name) {
  return getConfig(`features.${name}`);
}

/**
 * Get configuration of a specific client.
 *
 * @param {string} name Name of the client
 * @returns object
 */
function getClientConfig(name) {
  return getConfig(`clients.${name}`);
}

function removeInternalLinkFromUrl(url = '', keepQueryParams = false) {
  const hashIndex = url.indexOf('#');
  const questionIndex = url.indexOf('?');
  const internalLinkExist = hashIndex > 0;
  const queryExist = questionIndex > 0;
  const queryIsAfterInternalLink = questionIndex > hashIndex;
  if (internalLinkExist) {
    if (keepQueryParams && queryExist && queryIsAfterInternalLink) {
      return url.slice(0, hashIndex) + url.slice(questionIndex, url.length);
    }

    return url.slice(0, hashIndex);
  }
  return url;
}

function formatTranslationDataByLocalKey(translationDataByOrigKey) {
  const translationDataObjByLocalKey = {};
  for (const [key, value] of Object.entries(translationDataByOrigKey)) {
    translationDataObjByLocalKey[value.toLowerCase()] = key;
  }
  return translationDataObjByLocalKey;
}

function setTranslatedTextToSentenceObj(sentenceObj) {
  if (cleanString(sentenceObj.original_text) === cleanString(sentenceObj.translated_text)) {
    return;
  }
  let translatedTextsArr = [];
  if (sentenceObj.translated_text) {
    translatedTextsArr = sentenceObj.translated_text.split(
      globalConfig.sentence_merge_separator_receive
    );
  }

  const children = sentenceObj.children.map((child, index) => {
    child.translated_text = translatedTextsArr[index] ? translatedTextsArr[index] : '';
    return child;
  });
  sentenceObj.children = children;
  return sentenceObj;
}

function setTranslatedTextToSentenceMapChildren(sentenceMap) {
  const obj = {};
  Object.entries(sentenceMap).forEach(([key, value]) => {
    if (cleanString(value.original_text) !== cleanString(value.translated_text)) {
      let translatedTextsArr = [];
      if (value.translated_text) {
        translatedTextsArr = value.translated_text.split(
          globalConfig.sentence_merge_separator_receive
        );
      }

      const children = value.children.map((child, index) => {
        child.translated_text = translatedTextsArr[index];
        return child;
      });
      value.children = children;
    }
    obj[key] = value;
  });
  return obj;
}

// showing unauthorized message
function uiErrorContextEditor() {
  const element = document.getElementById('devnagri_uiErrorContextEditor');
  if (element) {
    element.remove();
  }
  const uiErrorEditor = document.createElement('div');
  uiErrorEditor.innerText = 'You are unauthorized please use new token';
  uiErrorEditor.classList.add('devnagri_uiErrorEditor');
  uiErrorEditor.id = 'devnagri_uiErrorContextEditor';
  const outerMostDiv = document.getElementById('devnagri_outermostdiv');
  outerMostDiv.appendChild(uiErrorEditor);
}

// this will create ui for unauthorized message
function showErrorMessage() {
  uiErrorContextEditor();
}

function compareArrays(a, b) {
  return a.length === b.length && a.every((element, index) => element === b[index]);
}

// this function is used for reloading when text is edited but don't want to send text to api
function webOwnerCancel() {
  location.reload();
}

// handling click event for editing
function handler(e) {
  if (
    ![
      'devnagri_owner_button Devnagri-tooltip-toggle',
      'devnagri_owner_send_button',
      'devnagri_owner_cancel_button',
      'devnagri_open_button',
      'devnagri_container',
      'devnagri_optionElement',
      'devnagri_seo_cancel',
      'devnagri_seo_submit',
      'devnagri_seo',
      'devnagri_logo',
      'devnagri_written_lang',
      'devnagri_upArrow',
      'devnagri_outermostdivclass'
    ].includes(e.target.className)
  ) {
    e.stopPropagation();
    e.preventDefault();
    e.stopImmediatePropagation();
  }
}

// async function getXPathForElement(el, xml, att = null) {
//   let xpath = '';
//   let pos;
//   let tempitem2;

//   while (el !== xml.documentElement) {
//     let nodeName = await el.nodeName
//     pos = 0;
//     tempitem2 = el;
//     while (tempitem2) {
//       if (tempitem2.nodeType === 1 && tempitem2.nodeName === nodeName) {
//         // If it is ELEMENT_NODE of the same name
//         pos += 1;
//       }
//       tempitem2 = tempitem2.previousSibling;
//     }

//     xpath = `*[name()='${nodeName}' and namespace-uri()='${
//       el.namespaceURI ?? ''
//     }'][${pos}]/${xpath}`;

//     el = el.parentNode;
//   }
//   xpath = `/*[name()='${xml.documentElement.nodeName}' and namespace-uri()='${
//     el.namespaceURI ?? ''
//   }']/${xpath}`;
//   if (att) {
//     xpath = `${xpath}/*[attr()='${att}' and namespace-uri()='${el.namespaceURI ?? ''}']`;
//   }
//   xpath = xpath.replace(/\/$/, '');
//   return xpath;
// }

function getXPathForElement(el, xml, att = null) {
  let xpath = '';
  let pos;
  let tempitem2;

  while (el && el !== xml.documentElement) {
    pos = 0;
    tempitem2 = el;
    while (tempitem2) {
      if (
        (tempitem2.nodeType === 1 || tempitem2.nodeType === 3) &&
        tempitem2.nodeName === el.nodeName
      ) {
        // If it is ELEMENT_NODE of the same name
        pos += 1;
      }
      tempitem2 = tempitem2.previousSibling;
    }

    xpath = el.nodeName
      ? `${el.nodeName.toLowerCase()}${pos > 1 ? `[${pos}]` : ''}/${xpath}`
      : xpath;

    el = el.parentNode;
  }
  xpath = `/${xml.documentElement.nodeName.toLowerCase()}/${xpath}`;

  xpath = xpath.replace(/\/$/, '');

  if (att) {
    xpath = `${xpath}#${att}`;
  }

  return xpath;
}

// This function helps us find the XPath of each element and its parent element.
function pushXpathToSentencesArray(node, text, document, att) {
  var xpath = getXPathForElement(node, document, att);
  var xpathParent = getXPathForElement(node.parentElement, document);
  var temp = { sentence: text, xpath: xpath, xpathParent: xpathParent };
  globalConfig.sentence['sentences'].push(temp);
  return { xpath, xpathParent };
}

function mergedSentencesXpath(sentenceNode, document) {
  const mergedSentences = Object.entries(sentenceNode).map(
    ([xpathParent, { children, original_text, _idx }]) => {
      let childXpath =
        children &&
        children.map((value) => {
          return getXPathForElement(value.node, document);
        });
      return {
        sentence: original_text,
        xpath: xpathParent,
        xpathParent,
        xpathChild: childXpath,
        _idx
      };
    }
  );
  return mergedSentences.filter((item) => item.sentence != undefined);
}

function isObjEmpty(obj) {
  return _.isEmpty(obj);
}

module.exports = {
  removeExtraSpacesInMiddle,
  cleanString,
  getConfig,
  getFeatureConfig,
  getClientConfig,
  removeInternalLinkFromUrl,
  formatTranslationDataByLocalKey,
  setTranslatedTextToSentenceObj,
  setTranslatedTextToSentenceMapChildren,
  uiErrorContextEditor,
  showErrorMessage,
  compareArrays,
  webOwnerCancel,
  handler,
  pushXpathToSentencesArray,
  getXPathForElement,
  mergedSentencesXpath,
  isObjEmpty
};
