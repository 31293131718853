const globalConfig = require('../../config');

module.exports = {
  async identifyScriptMode(config) {
    const proxy_config_keys = [
      // "accessToken",
      'domain',
      'requested_domain',
      'siteUrl',
      'linkedDomains',
      'existingTranslations'
    ];
    // at-least one of proxy keys exists in the config object
    if (proxy_config_keys.map((key) => config[key]).some(Boolean)) {
      globalConfig.script_type = 'proxy_browser';
    }
  },

  isProxy() {
    return globalConfig.script_type === 'proxy_browser';
  },

  isLiveJs() {
    return globalConfig.script_type === 'livejs_browser';
  }
};
