const global_variables = require('../../config');
const { cleanString } = require('./utils');

const INDEX_DB_NAME = global_variables.db;

async function addValuesInIndexObject(db, textObject) {
  try {
    // // Start a database transaction and get the notes object store
    // const indexDBValues = await new Promise((resolve, reject) => {
    const tx = await db.transaction([global_variables.subdomainObjectStore], 'readwrite');
    const store = await tx.objectStore(global_variables.subdomainObjectStore);
    for (const key of Object.keys(textObject)) {
      await store.put(textObject[key], key);
    }

    // Wait for the database transaction to complete
    // tx.oncomplete = function () { };
    // tx.addEventListener("error", () => {
    //   reject(null);
    // });
    // resolve();
    // });
    // return indexDBValues;
  } catch (error) {
    console.log(error);
  }
}

module.exports = {
  // indexDb methods start
  async storeValuesInIndexDB(textObject) {
    const dbReq = indexedDB.open(INDEX_DB_NAME, 1);
    try {
      const dbReqPromise = await new Promise((resolve, reject) => {
        dbReq.addEventListener('upgradeneeded', (event) => {
          global_variables.db = event.target.result;

          // check if subdomainObjectStore(language code) exist and check if store is already created if not, create it.
          if (
            global_variables.subdomainObjectStore &&
            !global_variables.db.objectStoreNames.contains(global_variables.subdomainObjectStore)
          ) {
            global_variables.devnagriCreateDataBaseStore = global_variables.db.createObjectStore(
              global_variables.subdomainObjectStore,
              {
                autoIncrement: true
              }
            );
          }
        });

        dbReq.addEventListener('success', async (event) => {
          global_variables.db = event.target.result;

          // check if subdomainObjectStore(language code) and store exist.
          if (
            global_variables.subdomainObjectStore &&
            global_variables.db.objectStoreNames.contains(global_variables.subdomainObjectStore)
          ) {
            await addValuesInIndexObject(global_variables.db, textObject);
            resolve();
          }
          reject(null);
        });
        dbReq.addEventListener('error', () => {
          reject(null);
        });
      });

      return dbReqPromise;
    } catch (error) {
      console.log(error);
    }
  },

  async getParticularValueFromIndexDB(text) {
    const dbOpenRequest = window.indexedDB.open(INDEX_DB_NAME, 1);
    try {
      const obStore = await new Promise((resolve, reject) => {
        dbOpenRequest.addEventListener('success', () => {
          global_variables.db = dbOpenRequest.result;

          const tx = global_variables.db.transaction(
            [global_variables.subdomainObjectStore],
            'readwrite'
          );

          // Wait for the database transaction to complete
          // tx.oncomplete = function () { };
          // tx.onerror = function () { };

          const obStore = tx.objectStore(global_variables.subdomainObjectStore);
          resolve(obStore);
        });
        dbOpenRequest.addEventListener('error', () => {
          reject(null);
        });
      });
      const translatedText = await new Promise((resolve, reject) => {
        if (typeof text === 'string') {
          const objectStoreRequest = obStore.get(text);

          objectStoreRequest.addEventListener('success', () => {
            const { result } = objectStoreRequest;
            resolve(result);
          });
          objectStoreRequest.addEventListener('error', () => {
            reject(null);
          });
        } else {
          reject(null);
        }
      });
      return translatedText;
    } catch (error) {
      console.log(error);
    }
  },

  async getValuesInIndexObject() {
    const dbOpenRequest = window.indexedDB.open(INDEX_DB_NAME, 1);
    try {
      const obStore = await new Promise((resolve, reject) => {
        dbOpenRequest.addEventListener('success', async () => {
          global_variables.db = dbOpenRequest.result;

          if (
            global_variables.subdomainObjectStore &&
            global_variables.db.objectStoreNames.contains(global_variables.subdomainObjectStore)
          ) {
            const tx = await global_variables.db.transaction(
              [global_variables.subdomainObjectStore],
              'readwrite'
            );

            // Wait for the database transaction to complete
            // tx.oncomplete = function () { };
            // tx.onerror = function (event) { };

            const obStore = await tx.objectStore(global_variables.subdomainObjectStore);
            resolve(obStore);
          }
          reject(null);
        });
        dbOpenRequest.addEventListener('error', () => {
          reject(null);
        });
      });
      await new Promise((resolve, reject) => {
        const request = obStore.openCursor();

        request.addEventListener('success', async (event) => {
          const cursor = event.target.result;
          if (cursor) {
            const key = cursor.primaryKey;
            const value = cursor.value;
            if (global_variables.translation_api_global_data) {
              global_variables.translation_api_global_data[cleanString(key)] = value;
              if (Object.keys(global_variables.arr_nodes).includes(cleanString(key))) {
                global_variables.arr_nodes[`${cleanString(key)}`].translatedText =
                  global_variables.translation_api_global_data[cleanString(key)];
              }
            }
            cursor.continue();
          } else {
            return resolve(request);
          }
        });
        request.addEventListener('error', () => {
          return reject(null);
        });
      });
    } catch (error) {
      console.log(error);
    }
  }
};
