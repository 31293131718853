const { isTextTranslatedText } = require('../helpers/app-helpers');
const { isNodeASentence } = require('../helpers/node-helpers');
const { isProxy } = require('../helpers/mode');
const myGov = require('../clients/mygov');
const { cleanString } = require('../helpers/utils');
const { storeValuesInIndexDB } = require('../helpers/browser-storage.helpers');

function addDynamicTranslationListeners(
  globalConfig,
  checkNodeType,
  domNodeInsertedHandler,
  translateAllFramesOnPage,
  traverseNodes,
  GetTranslationsApi,
  config
) {
  const enableDynamicTranslation = !config.enable_static_content;

  if (enableDynamicTranslation && isProxy()) {
    document.addEventListener('DOMCharacterDataModified', (event) => {
      if (!globalConfig.edit_mode_enabled) {
        if (globalConfig.source_lang_code === globalConfig.selected_lang_code) {
          return;
        }
        if (globalConfig.source_lang_code !== globalConfig.selected_lang_code) {
          const cleanValue = cleanString(event.target.textContent);
          if (cleanValue && !isTextTranslatedText(cleanValue)) {
            if (globalConfig.enable_blacklist) {
              const { parentElement } = event.target;
              // node blacklist by class condition
              if (
                globalConfig.blacklist_classes
                  .map(
                    (item) =>
                      parentElement &&
                      parentElement.getAttribute('class') &&
                      parentElement.getAttribute('class').includes(item)
                  )
                  .some(Boolean)
              ) {
                return;
              }

              // node blacklist by id condition
              if (
                parentElement &&
                parentElement.getAttribute &&
                parentElement.getAttribute('id') &&
                globalConfig.blacklist_ids.includes(parentElement.getAttribute('id'))
              ) {
                return;
              }
            }

            const node = event.target;
            if (
              globalConfig.enable_sentence_merging &&
              node &&
              node.parentElement &&
              isNodeASentence(node.parentElement)
            ) {
              traverseNodes(node.parentElement, checkNodeType);
            } else {
              traverseNodes(event.target, checkNodeType);
            }
          }
        }
      }
    });
  }

  // for getting notify dynamic rendering
  // document.addEventListener('DOMNodeInserted', (e) => {
  //   if (!globalConfig.edit_mode_enabled) {
  //     if (globalConfig.source_lang_code === globalConfig.selected_lang_code) {
  //       return;
  //     }
  //     if (Object.values(globalConfig.frames_translation_status_map).some((val) => val)) {
  //       translateAllFramesOnPage();
  //     }
  //     domNodeInsertedHandler(e);
  //   }

  //   if (myGov.isEnabled()) {
  //     myGov.fixForLinksMissMatchWithDynamicContent();
  //   }
  // });

  const targetNode = document.body; // You can change this to any specific target node

  const observerConfig = {
    childList: true,
    attributes: true,
    characterData: true,
    subtree: true
  };

  const callback = (mutationsList) => {
    for (const mutation of mutationsList) {
      if (mutation.type === 'childList') {
        for (const node of mutation.addedNodes) {
          if (node.nodeType === Node.ELEMENT_NODE) {
            const e = { target: node }; // Create an event-like object

            if (!globalConfig.edit_mode_enabled) {
              if (globalConfig.source_lang_code === globalConfig.selected_lang_code) {
                return;
              }
              if (Object.values(globalConfig.frames_translation_status_map).some((val) => val)) {
                translateAllFramesOnPage();
              }
              domNodeInsertedHandler(e);
            }

            if (myGov.isEnabled()) {
              myGov.fixForLinksMissMatchWithDynamicContent();
            }
          }
        }
      }
    }
  };

  const observer = new MutationObserver(callback);

  observer.observe(targetNode, observerConfig);
  GetTranslationsApi();
}

async function initializeGlobalConfig(config, globalConfig, cleanExistingTranslationObj) {
  const enableDynamicTranslation = !config.enable_static_content;
  globalConfig.enable_object_mapping = config.enable_object_mapping;
  globalConfig.translation_api_url = `${globalConfig.api_baseurl}/translations?url=${globalConfig.document_url_full}`;
  globalConfig.update_api_url = `${globalConfig.api_baseurl}/translations`;

  globalConfig.target_lang_code = config.target_lang_code;

  globalConfig.api_key = config.apiKey;
  globalConfig.translation_api_global_data = isProxy()
    ? cleanExistingTranslationObj(config.existingTranslations)
    : {};

  if (
    enableDynamicTranslation &&
    globalConfig.translation_api_global_data &&
    globalConfig.status_api_global_data &&
    !globalConfig.status_api_global_data.is_demo
  ) {
    await storeValuesInIndexDB(globalConfig.translation_api_global_data);
  }

  if (isProxy()) {
    globalConfig.domain = config.domain;
    globalConfig.requested_domain = config.requested_domain;
    globalConfig.subdomain_object_urls_hash_map = config.subdomain_object_urls_hash_map;
  }

  globalConfig.config = config;

  globalConfig.frame_ids.forEach(
    (frameId) => (globalConfig.frames_translation_status_map[frameId] = true)
  );
}

module.exports = {
  addDynamicTranslationListeners,
  initializeGlobalConfig
};
