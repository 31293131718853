const global_variables = require('../../config');

const { cleanString, isObjEmpty } = require('./utils');
const { setDropdownPosition } = require('../services/dropdown-service');
const { isLiveJs, isProxy } = require('./mode');
const { validationHelperFunctions } = require('../../proxy/helpers/response-validator-helper.js');

function print(level, ...data) {
  if (level <= global_variables.log_level) {
    if (level === 1) {
      return console.error(`DevLog-${level} ${new Date()} | `, ...data);
    }
    if (level === 2) {
      return console.warn(`DevLog-${level} ${new Date()} | `, ...data);
    }
    return console.log(`DevLog-${level} ${new Date()} | `, ...data);
  }
}
// this function is shifted in authentication services
function access_token() {
  if (global_variables._access_token) {
    return global_variables._access_token;
  }
  const tokenFromLocalStorage = localStorage.getItem('devnagri_access_token');
  if (tokenFromLocalStorage) {
    global_variables._access_token = tokenFromLocalStorage;
    return tokenFromLocalStorage;
  }
  return '';
}

async function FormatTranslations(sentences, withXPath = false) {
  const sentenceObj = {};
  sentences.map((x) => {
    global_variables.arr_translated_text[cleanString(x.sentence)] = x.translation;

    sentenceObj[x.xpath] = {
      segment: x.sentence,
      translation: x.translation,
      xpathChild: x.xpathChild
    };
  });
  return withXPath ? sentenceObj : global_variables.arr_translated_text;
}

async function calculateMissingTranslationPercentage() {
  const translationData = global_variables.translation_api_global_data;
  const nodesCount = Object.keys(global_variables.arr_nodes).length;
  if (!translationData || nodesCount == 0) {
    return;
  }
  const missingTranslations = [];
  for (const key of Object.keys(global_variables.arr_nodes)) {
    let txtOriginal = key;
    if (txtOriginal.startsWith(global_variables.meta_prefix)) {
      txtOriginal = txtOriginal.replace(global_variables.meta_prefix, '');
    }
    if (translationData[cleanString(txtOriginal)]) {
      global_variables.arr_nodes[cleanString(key)].translatedText =
        translationData[cleanString(txtOriginal)];
    } else if (!missingTranslations.includes(key)) {
      missingTranslations.push(key);
    }
  }

  const missingPercentage = (missingTranslations.length * 100) / nodesCount;
  return missingPercentage;
}

function replaceEnglishDomainWithLocalDomain({ url, englishDomain, localDomain }) {
  let hrefUrl = url;
  if (global_variables.domain && !global_variables.domain.includes('www')) {
    if (hrefUrl.includes(global_variables.domain)) {
      hrefUrl = hrefUrl.replaceAll('https://www.', 'https://');
      hrefUrl = hrefUrl.replaceAll('http://www.', 'http://');
    }
  }

  const englishDomainWithoutProtocol = englishDomain
    .replaceAll('https://', '')
    .replaceAll('http://', '');
  const localDomainWithoutProtocol = localDomain
    .replaceAll('https://', '')
    .replaceAll('http://', '');

  const secureEnglishDomain = `https://${englishDomainWithoutProtocol}`;
  const secureLocalDomain = `https://${localDomainWithoutProtocol}`;

  const unsecureEnglishDomain = `http://${englishDomainWithoutProtocol}`;
  const unsecureLocalDomain = `http://${localDomainWithoutProtocol}`;

  let hrefUrlSlash = `${hrefUrl}/`;

  if (hrefUrlSlash.includes(localDomainWithoutProtocol + '/')) {
    return hrefUrl;
  }

  let newUrl = hrefUrl;
  newUrl = newUrl.replaceAll(secureEnglishDomain, secureLocalDomain);
  newUrl = newUrl.replaceAll(unsecureEnglishDomain, unsecureLocalDomain);
  return newUrl;
}

function getLocalObjectUrl(englishUrl) {
  return global_variables.subdomain_object_urls_hash_map[formatUrl(englishUrl)] &&
    global_variables.subdomain_object_urls_hash_map[formatUrl(englishUrl)].local_url
    ? global_variables.subdomain_object_urls_hash_map[englishUrl].local_url
    : '';
}

function getLocalObjectAlt(englishUrl) {
  return global_variables.subdomain_object_urls_hash_map[formatUrl(englishUrl)] &&
    global_variables.subdomain_object_urls_hash_map[formatUrl(englishUrl)].alt_text
    ? global_variables.subdomain_object_urls_hash_map[englishUrl].alt_text
    : '';
}

function formatUrl(url = '') {
  const trimmedUrl = url.trim();
  return removeTrailingSlashesFromUrl(trimmedUrl);
}

function removeTrailingSlashesFromUrl(url) {
  if (url.endsWith('/')) {
    return removeTrailingSlashesFromUrl(url.slice(0, -1));
  }
  return url;
}

function isTextTranslatedText(text = '') {
  const textWithoutMeta = text.replace(global_variables.meta_prefix, '');
  const textWithReplacedSeperator = textWithoutMeta.replaceAll(
    global_variables.sentence_merge_separator_send,
    global_variables.sentence_merge_separator_receive
  );
  if (!textWithReplacedSeperator) {
    return true;
  }
  // if (!global_variables.translation_api_global_data_by_local_key) {
  //   return true;
  // }
  return !!global_variables.translation_api_global_data_by_local_key[
    textWithReplacedSeperator.toLowerCase()
  ];
}

function isUrlLangStatusActive(selected_lang_code) {
  const statusMap = global_variables.lang_status_map;
  return statusMap[selected_lang_code] === 'ACTIVE';
}

function getSentenceCombinedOriginalText(sentenceNode) {
  let text = '';
  sentenceNode.children.forEach((child, index) => {
    text += `${index > 0 ? global_variables.sentence_merge_separator_send : ''}${child.text}`;
  });
  return text;
}

function replaceNbsps(str) {
  const re = new RegExp(String.fromCharCode(160), 'g');
  return str.replace(re, ' ');
}

function escapeForwardSlashes(str) {
  const newStr = str.replaceAll('/', '\\/');
  return newStr;
}

// check if we already have status api res data, if yes - configure it. else return false.
function isStatusDataAvailable() {
  if (!isObjEmpty(global_variables.status_api_global_data)) {
    configureStatusApiResObject(global_variables.status_api_global_data);
    setDropdownPosition(global_variables.status_api_global_data.ui_position);

    return true;
  }

  return false;
}

// this function configures the status response object
function configureStatusApiResObject(statusResObj) {
  // enable sentence merging as per url date start
  let urlDate = global_variables.sentence_merging_fallback_url_date;
  if (statusResObj.url_date) {
    urlDate = statusResObj.url_date;
  }

  if (new Date(urlDate) > new Date(global_variables.sentence_merging_initial_url_date)) {
    global_variables.enable_sentence_merging = true;
  } else {
    global_variables.enable_sentence_merging = false;
  }
  // enable sentence merging as per url date end

  if (statusResObj.enableLanguageDropdownUI) {
    global_variables.should_render_ui = 'native';
  } else {
    global_variables.should_render_ui = 'custom';
  }

  if (statusResObj.urlStatusArray && Array.isArray(statusResObj.urlStatusArray)) {
    const langStatusMap = {};
    statusResObj.urlStatusArray.forEach((item) => {
      langStatusMap[item.code] = item.status;
    });
    global_variables.lang_status_map = langStatusMap;
  }

  if (isLiveJs()) {
    global_variables.source_lang_code = statusResObj.source_lang_code;
  }

  if (isLiveJs() && statusResObj.enableSubdomainRedirect && !global_variables.web_owner) {
    global_variables.selected_lang_code = global_variables.source_lang_code;
  }

  if (isProxy() && statusResObj.urlStatus === 'INACTIVE') {
    window.location.replace(global_variables.document_url_full);
  }

  if (statusResObj.enable_translate_manual_approved_segments) {
    global_variables.enable_translate_manual_approved_segments =
      statusResObj.enable_translate_manual_approved_segments;
  }

  // if (
  //   statusResObj &&
  //   statusResObj.enable_object_mapping
  // ) {
  //   global_variables.enable_object_mapping = true;
  //   await getObjectUrlsApi();
  // }

  // if (
  //   !global_variables.page_view_api_count &&
  //   global_variables.selected_lang_code !=
  //     statusResObj.source_lang_code
  // ) {
  //   Promise.all([pageViewsApi(global_variables.page_view_api_url)]);
  // }
  // ++global_variables.page_view_api_count;

  if ((statusResObj.enableSubdomainRedirect || isProxy()) && !global_variables.web_owner) {
    localStorage.setItem('devnagri_default_lang_code', 'en');
    localStorage.setItem('devnagri_selected_lang_code', 'en');
  }
  global_variables.lang = statusResObj.languages;

  const enable_exclude_block = statusResObj ? !!statusResObj.enable_exclude_block : false;

  const exclude_blocks = statusResObj.exclude_blocks ? statusResObj.exclude_blocks : [];

  global_variables.enable_blacklist = enable_exclude_block;

  if (enable_exclude_block) {
    const blacklist_data = exclude_blocks.map((item) => item.selector);
    global_variables.blacklist_data = blacklist_data;
    const id_selectors = blacklist_data.filter((item) => item.includes('#'));
    global_variables.blacklist_ids = id_selectors.map((selector) => selector.replaceAll('#', ''));
    const class_selectors = blacklist_data.filter((item) => item.includes('.'));
    global_variables.blacklist_classes = class_selectors.map((selector) =>
      selector.replaceAll('.', '')
    );
  }

  const enable_include_block = statusResObj ? !!statusResObj.enable_include_block : false;

  const include_blocks = statusResObj.include_blocks ? statusResObj.include_blocks : [];

  global_variables.enable_whitelist = enable_include_block;

  if (enable_include_block) {
    const whitelist_data = include_blocks.map((item) => item.selector);
    global_variables.whitelist_data = whitelist_data;
    const id_selectors = whitelist_data.filter((item) => item.includes('#'));
    global_variables.whitelist_ids = id_selectors.map((selector) => selector.replaceAll('#', ''));
    const class_selectors = whitelist_data.filter((item) => item.includes('.'));
    global_variables.whitelist_classes = class_selectors.map((selector) =>
      selector.replaceAll('.', '')
    );
  }

  if (isProxy()) {
    global_variables.linkedDomains = statusResObj.linked_domains;
  }
  global_variables.frame_ids = statusResObj.iframe_ids;
  global_variables.frame_ids.forEach(
    (frameId) => (global_variables.frames_translation_status_map[frameId] = true)
  );
}

// status api response schema
const statusResSchemaObject = {
  subscription: (val, path) => validationHelperFunctions.isNonEmptyString(val, path),
  urlStatus: (val, path) => validationHelperFunctions.isNonEmptyString(val, path),
  enableSubdomainRedirect: (val, path) => validationHelperFunctions.isBoolean(val, path),
  enableLanguageDropdownUI: (val, path) => validationHelperFunctions.isBoolean(val, path),
  enable_object_mapping: (val, path) => validationHelperFunctions.isBoolean(val, path),
  languages: (val, path) =>
    validationHelperFunctions.validateArrayObjects(
      val,
      {
        code: validationHelperFunctions.isNonEmptyString,
        name: validationHelperFunctions.isNonEmptyString
      },
      path
    ),
  subdomains: (val, path) =>
    validationHelperFunctions.validateArrayObjects(
      val,
      {
        subdomain: validationHelperFunctions.isNonEmptyString,
        code: validationHelperFunctions.isNonEmptyString,
        name: validationHelperFunctions.isNonEmptyString
      },
      path
    ),
  enable_exclude_block: (val, path) => validationHelperFunctions.isBoolean(val, path),
  exclude_blocks: (val, path) => validationHelperFunctions.isArray(val, path),
  enable_include_block: (val, path) => validationHelperFunctions.isBoolean(val, path),
  include_blocks: (val, path) => validationHelperFunctions.isArray(val, path),
  iframe_ids: (val, path) => validationHelperFunctions.isArray(val, path),
  ui_position: (val, path) =>
    (typeof val === 'object' &&
      ['bottom', 'right', 'left'].every(
        (key) =>
          val[key] === null ||
          val[key] === '' ||
          validationHelperFunctions.isNonEmptyString(val[key], `${path}.${key}`)
      )) ||
    (errors[path] = `Expected object with optional non-empty strings at ${path}`),
  linked_domains: (val, path) =>
    validationHelperFunctions.validateArrayObjects(
      val,
      {
        subdomain: validationHelperFunctions.isNonEmptyString,
        domain: validationHelperFunctions.isNonEmptyString,
        code: validationHelperFunctions.isNonEmptyString
      },
      path
    ),
  urlStatusArray: (val, path) =>
    validationHelperFunctions.validateArrayObjects(
      val,
      {
        code: validationHelperFunctions.isNonEmptyString,
        status: validationHelperFunctions.isNonEmptyString
      },
      path
    ),
  source_lang_code: (val, path) => validationHelperFunctions.isNonEmptyString(val, path),
  url_date: (val, path) => validationHelperFunctions.isDate(val, path),
  is_demo: (val, path) => validationHelperFunctions.isBoolean(val, path),
  enable_translate_manual_approved_segments: (val, path) =>
    validationHelperFunctions.isNumber(val, path),
  enable_robots_index: (val, path) => validationHelperFunctions.isBoolean(val, path)
};

/**
 * description: type validation on status res object
 * @param {Object} statusRes
 * @param {Object} schema
 * @returns boolean
 */
function validateStatusApiResObject(statusRes, schema) {
  const isValid = validationHelperFunctions.validateObject(statusRes, schema);

  return isValid;
}

// validate status api response
const validateStatusRes = (statusResObj) => {
  const result = validateStatusApiResObject(statusResObj, statusResSchemaObject);

  if (!result) {
    console.log(' -> Status response validation error: ', validationHelperFunctions.errors);
    throw new Error(`Status response validation error: ${validationHelperFunctions.errors}`);
  }
  return;
};

module.exports = {
  print,
  access_token,
  FormatTranslations,
  calculateMissingTranslationPercentage,
  replaceEnglishDomainWithLocalDomain,
  getLocalObjectUrl,
  getLocalObjectAlt,
  formatUrl,
  isTextTranslatedText,
  isUrlLangStatusActive,
  getSentenceCombinedOriginalText,
  replaceNbsps,
  escapeForwardSlashes,
  isStatusDataAvailable,
  configureStatusApiResObject,
  validateStatusRes
};
