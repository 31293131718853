const global_variables = require('../../config');

const { cleanString } = require('./utils');

function print(level, ...data) {
  if (level <= global_variables.log_level) {
    if (level === 1) {
      return console.error(`DevLog-${level} ${new Date()} | `, ...data);
    }
    if (level === 2) {
      return console.warn(`DevLog-${level} ${new Date()} | `, ...data);
    }
    return console.log(`DevLog-${level} ${new Date()} | `, ...data);
  }
}
// this function is shifted in authentication services
function access_token() {
  if (global_variables._access_token) {
    return global_variables._access_token;
  }
  const tokenFromLocalStorage = localStorage.getItem('devnagri_access_token');
  if (tokenFromLocalStorage) {
    global_variables._access_token = tokenFromLocalStorage;
    return tokenFromLocalStorage;
  }
  return '';
}

async function FormatTranslations(sentences, withXPath = false) {
  const sentenceObj = {};
  sentences.map((x) => {
    global_variables.arr_translated_text[cleanString(x.sentence)] = x.translation;

    sentenceObj[x.xpath] = {
      segment: x.sentence,
      translation: x.translation,
      xpathChild: x.xpathChild
    };
  });
  return withXPath ? sentenceObj : global_variables.arr_translated_text;
}

async function calculateMissingTranslationPercentage() {
  const translationData = global_variables.translation_api_global_data;
  const nodesCount = Object.keys(global_variables.arr_nodes).length;
  if (!translationData || nodesCount == 0) {
    return;
  }
  const missingTranslations = [];
  for (const key of Object.keys(global_variables.arr_nodes)) {
    let txtOriginal = key;
    if (txtOriginal.startsWith(global_variables.meta_prefix)) {
      txtOriginal = txtOriginal.replace(global_variables.meta_prefix, '');
    }
    if (translationData[cleanString(txtOriginal)]) {
      global_variables.arr_nodes[cleanString(key)].translatedText =
        translationData[cleanString(txtOriginal)];
    } else if (!missingTranslations.includes(key)) {
      missingTranslations.push(key);
    }
  }

  const missingPercentage = (missingTranslations.length * 100) / nodesCount;
  return missingPercentage;
}

function replaceEnglishDomainWithLocalDomain({ url, englishDomain, localDomain }) {
  let hrefUrl = url;
  if (global_variables.domain && !global_variables.domain.includes('www')) {
    if (hrefUrl.includes(global_variables.domain)) {
      hrefUrl = hrefUrl.replaceAll('https://www.', 'https://');
      hrefUrl = hrefUrl.replaceAll('http://www.', 'http://');
    }
  }

  const englishDomainWithoutProtocol = englishDomain
    .replaceAll('https://', '')
    .replaceAll('http://', '');
  const localDomainWithoutProtocol = localDomain
    .replaceAll('https://', '')
    .replaceAll('http://', '');

  const secureEnglishDomain = `https://${englishDomainWithoutProtocol}`;
  const secureLocalDomain = `https://${localDomainWithoutProtocol}`;

  const unsecureEnglishDomain = `http://${englishDomainWithoutProtocol}`;
  const unsecureLocalDomain = `http://${localDomainWithoutProtocol}`;

  let hrefUrlSlash = `${hrefUrl}/`;

  if (hrefUrlSlash.includes(localDomainWithoutProtocol + '/')) {
    return hrefUrl;
  }

  let newUrl = hrefUrl;
  newUrl = newUrl.replaceAll(secureEnglishDomain, secureLocalDomain);
  newUrl = newUrl.replaceAll(unsecureEnglishDomain, unsecureLocalDomain);
  return newUrl;
}

function getLocalObjectUrl(englishUrl) {
  return global_variables.subdomain_object_urls_hash_map[formatUrl(englishUrl)] &&
    global_variables.subdomain_object_urls_hash_map[formatUrl(englishUrl)].local_url
    ? global_variables.subdomain_object_urls_hash_map[englishUrl].local_url
    : '';
}

function getLocalObjectAlt(englishUrl) {
  return global_variables.subdomain_object_urls_hash_map[formatUrl(englishUrl)] &&
    global_variables.subdomain_object_urls_hash_map[formatUrl(englishUrl)].alt_text
    ? global_variables.subdomain_object_urls_hash_map[englishUrl].alt_text
    : '';
}

function formatUrl(url = '') {
  const trimmedUrl = url.trim();
  return removeTrailingSlashesFromUrl(trimmedUrl);
}

function removeTrailingSlashesFromUrl(url) {
  if (url.endsWith('/')) {
    return removeTrailingSlashesFromUrl(url.slice(0, -1));
  }
  return url;
}

function isTextTranslatedText(text = '') {
  const textWithoutMeta = text.replace(global_variables.meta_prefix, '');
  const textWithReplacedSeperator = textWithoutMeta.replaceAll(
    global_variables.sentence_merge_separator_send,
    global_variables.sentence_merge_separator_receive
  );
  if (!textWithReplacedSeperator) {
    return true;
  }
  if (!global_variables.translation_api_global_data_by_local_key) {
    return true;
  }
  return !!global_variables.translation_api_global_data_by_local_key[
    textWithReplacedSeperator.toLowerCase()
  ];
}

function isUrlLangStatusActive(selected_lang_code) {
  const statusMap = global_variables.lang_status_map;
  return statusMap[selected_lang_code] === 'ACTIVE';
}

function getSentenceCombinedOriginalText(sentenceNode) {
  let text = '';
  sentenceNode.children.forEach((child, index) => {
    text += `${index > 0 ? global_variables.sentence_merge_separator_send : ''}${child.text}`;
  });
  return text;
}

function replaceNbsps(str) {
  const re = new RegExp(String.fromCharCode(160), 'g');
  return str.replace(re, ' ');
}

function escapeForwardSlashes(str) {
  const newStr = str.replaceAll('/', '\\/');
  return newStr;
}

module.exports = {
  print,
  access_token,
  FormatTranslations,
  calculateMissingTranslationPercentage,
  replaceEnglishDomainWithLocalDomain,
  getLocalObjectUrl,
  getLocalObjectAlt,
  formatUrl,
  isTextTranslatedText,
  isUrlLangStatusActive,
  getSentenceCombinedOriginalText,
  replaceNbsps,
  escapeForwardSlashes
};
